import { computed, Ref, ref, set, useContext } from '@nuxtjs/composition-api'
import config from '~/utils/config/config'
import getImageUrl from '~/utils/helpers/image-url'
import {
	AvailableStatus,
	ProductEntity,
	ProductItem,
} from '~/utils/models/product.entity'

export default function useProductCardFields(
	item?: Ref<ProductEntity> | undefined,
  getLocalValue?: Function | undefined,
) {
  const $getLocaleValue = getLocalValue || useContext().$getLocaleValue
	const isFullImage = computed(() => false)
	const activeVariation = computed({
		get(): number {
			return item?.value.activeVariationId as number
		},
		set(id: number) {
			item?.value.setActiveVariation(id)
		},
	})

	const activeItem = computed(() => {
		return item?.value.activeItem
	})
	const topSale = computed(() => {
		return !!activeItem.value?.top_sale_label
	})
	const isPopular = computed(() => {
		return !!activeItem.value?.popular_label
	})

	const availableStatus = computed(() => {
		return activeItem.value?.stock_status
	})
	const defaultMedia = computed(() => {
		return (
			activeItem.value?.media &&
			activeItem.value?.media[0] &&
			activeItem.value?.media[0].attributes
		)
	})
	const image = computed(() => {
		return (
			activeItem.value?.defaultImage ||
			require('@/assets/icons/no-photo.svg')
		)
	})

	const name = computed(() => {
		const titleStr = $getLocaleValue(item?.value.name)
    // eslint-disable-next-line no-prototype-builtins,no-nested-ternary
    const titleDefault = item?.value?.name?.ru
      ? item.value.name.ru
      : item?.value?.name?.ua
      ? item.value.name.ua
      : 'No Name'
    return titleStr || titleDefault
	})
	const rating = computed(() => {
		return parseInt(item?.value.review_rating as string)
	})
	const totalReviews = computed(() => {
		return item?.value.review_count
	})
	const sku = computed(() => activeItem.value?.sku)

	const hasSale = computed(() => activeItem.value?.hasSale)

	const price = computed(() => activeItem.value?.currentPrice)

	const oldPrice = computed(() => activeItem.value?.oldPrice)

	const sale = computed(() => activeItem.value?.sale)

	const variations = computed(() => {
		return item?.value.groupedVariations
	})
	const hasVariations = computed(() => item?.value.hasVariations)
	const isAvailable = computed(
		() => availableStatus.value === AvailableStatus.available || availableStatus.value === AvailableStatus.gettingOut
	)
	const isGettingOut = computed(
		() => availableStatus.value === AvailableStatus.gettingOut
	)
  const brand = computed(() => item?.value.productBrand)
  const categoriesTreeNames = computed(() => {
    const categoriesTree = item?.value.categoriesTree
    const names: string[] = []
    if (categoriesTree?.length) {
      for (let i = 0; i < categoriesTree.length; i += 1) {
        const { name: categoryName } = categoriesTree[i]
        const categoryLocaleName = $getLocaleValue(categoryName)
        // eslint-disable-next-line no-nested-ternary
        const categoryNameDefault = categoryName?.ru ? categoryName.ru : categoryName?.ua ? categoryName.ua : 'No Name'

        names.push(categoryLocaleName || categoryNameDefault)
      }
    }

    return names
  })
	return {
		isAvailable,
		isGettingOut,
		hasVariations,
		isFullImage,
		topSale,
		isPopular,
		name,
		hasSale,
		price,
		oldPrice,
		sale,
		availableStatus,
		image,
		variations,
		activeVariation,
		rating,
		totalReviews,
		sku,
    brand,
    categoriesTreeNames,
	}
}
