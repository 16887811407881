import { ref, watch } from '@nuxtjs/composition-api'
import { plainToClass } from "class-transformer"

import useProductCardFields from '~/components/ProductCard/product-card-fields'
import { beautyPrice } from '~/utils/helpers/beauty-price'
import {
  ProductEntity, ProductItem,
} from '~/utils/models/product.entity'
import { BrandEntity } from '~/utils/models/brand.entity'
import { CategoryEntity } from '~/utils/models/category.entity'

const getGtagCategories = (categoriesTreeNames: string[]) => {
  const gtagCategories: { [key: string]: string } = {}
  const categories = categoriesTreeNames || []

  for (let i = 0; i < categories.length; i += 1) {
    gtagCategories[`item_category${i < 1 ? '' : i + 1}`] = categories[i]
  }

  return gtagCategories
}

const getCategoriesNames = (productCategories: CategoryEntity[], getLocaleValue: Function) => {
  const names: string[] = []
  if (productCategories?.length) {
    for (let i = 0; i < productCategories.length; i += 1) {
      const { name: categoryName } = productCategories[i]
      const categoryLocaleName = getLocaleValue(categoryName)
      // eslint-disable-next-line no-nested-ternary
      const categoryNameDefault = categoryName?.ru ? categoryName.ru : categoryName?.ua ? categoryName.ua : 'No Name'

      names.push(categoryLocaleName || categoryNameDefault)
    }
  }

  return names
}

// Use only in setup function nuxt
export default function transformProductToGtag(
  product: ProductEntity,
  getLocalValue: Function | undefined,
  quantity = 1,
  position = 1,
) {
  const productRef = ref(plainToClass(ProductEntity, product))
  watch(productRef, () => {
    productRef.value = plainToClass(ProductEntity, productRef.value)
  })
  const productFields = useProductCardFields(productRef, getLocalValue)
  const { activeVariation } = productFields

  return getGtagObjectFromProductFields(activeVariation.value || productRef.value.id, productFields, quantity, position)
}

export function getGtagObjectFromProductFields(productId: number, productCardFields: any, quantity = 1, position = 1) {
  const { name, price, brand, categoriesTreeNames, oldPrice } = productCardFields
  // price without the discount is showing for analytics
  const priceForGtag = oldPrice?.value || price.value
  const discount = oldPrice?.value ? oldPrice.value - price.value : 0

  return {
    item_id: productId,
    item_name: name?.value ? name.value : 'No name',
    item_brand: brand?.value?.name || '',
    ...getGtagCategories(categoriesTreeNames?.value || []),
    quantity,
    index: position,
    price: beautyPrice(priceForGtag * quantity),
    discount: beautyPrice(discount * quantity),
  }
}

export function transformProductItemToGtag(
  productItem: ProductItem,
  getLocaleValue: Function,
  quantity = 1,
  productBrand: BrandEntity,
  productCategories: CategoryEntity[],
  position = 1,
) {
  const titleLocal = getLocaleValue(productItem.title)
  // eslint-disable-next-line no-prototype-builtins
  const titleDefault = productItem.title?.ru ? productItem.title?.ru : productItem.title?.ua || 'No name'
  const item_name = titleLocal || titleDefault
  const { price, sale_price } = productItem

  return {
    item_id: productItem.id,
    item_brand: productBrand?.name || '',
    ...getGtagCategories(getCategoriesNames(productCategories, getLocaleValue)),
    item_name,
    quantity,
    index: position,
    price: beautyPrice(+price * quantity),
    discount: (+sale_price ? +price - +sale_price : 0) * quantity,
  }
}
